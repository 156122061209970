<template>
  <div class="property" :class="{'property--bold': bold, 'property--lg': lg}">
    <div class="property__name" :class="{'property__name--small': smallName}">
      <span v-if="icon" class="property__icon" :class="`icon-${icon}`"></span>
      {{ name }}
      <div v-if="removable" class="property__remove" @click="emit('remove')">
        (Entfernen)
      </div>
    </div>
    <div
      class="property__value"
      :class="{'is-blurry': loading, 'property__value--large': lgValue}"
    >
      {{ value }}
    </div>
  </div>
</template>
<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const emit = defineEmits(['remove']);

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number],
    default: '',
  },
  bold: {
    type: Boolean,
    default: false,
  },
  lg: {
    type: Boolean,
    default: false,
  },
  lgValue: {
    type: Boolean,
    default: false,
  },
  smallName: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  removable: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss">
.property {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--bold {
    font-weight: $font-bold;
  }

  &--lg {
    font-size: $text-xl;
  }
}

.property__icon {
  font-size: $text-xs;
  background: currentColor;
  padding: $spacing-xxxs $spacing-xs;
  border-radius: 2px;

  &:before {
    color: $color-white;
  }
}

.property__name {
  margin-right: $spacing-xs;
  display: flex;
  align-items: center;
  gap: $spacing-xxs;
  // white-space: nowrap;

  &--small {
    font-size: $text-xs;
  }
}

.property__value {
  white-space: nowrap;
  // margin-left: $spacing-xs;

  &--large {
    font-size: $text-xl;
  }
}

.property__remove {
  margin-left: 0.5rem;
  color: $color-gray;
  font-size: $text-xs;
}
</style>
